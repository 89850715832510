body {
  background-color: #f0f0f0;
}

body.dark-mode {
  background-color: #333;
}


.login-div {
  display: grid;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.login-form {
  max-width: 500px;
  width: 300px;
  margin-top: 20px;
}

.header-main {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.username-style {
  //marginRight:'10px', font:'font-family: "Times New Roman", Times, serif;'
  margin-right: 10px;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-size: 15px;
  font-style: italic;
}

.ui.selection.dropdown {
  margin: 5px;
}

.ui.selection.dropdown {
  min-width: 5em!important;
}

.div-buttons-table {
  display: flex;
  justify-content: space-between;
}

.datepicker-log {
  margin: 5px;

}


.ui.fitted.toggle.checkbox {
  height: 20px;
  margin-right: 15px;
  &.dark {
    input[type="checkbox"]:not(:checked) + label {
      background-color: #737373 !important;
      border-radius: 10px;
      width: 50px;
    }
  }
}


.ui.slider.checkbox {
  margin-top: 10px;
}

.ui.slider.checkbox input:focus:checked~.box:before,
.ui.slider.checkbox input:focus:checked~label:before {
  background-color: #2185d0 !important;

}

.ui.slider.checkbox input:checked~.box,
.ui.slider.checkbox input:checked~label {
  &:before, :hover, :focus {
    background-color: #2185d0 !important;
  }
}

.ui.slider.checkbox.dark .box,
.ui.slider.checkbox.dark label {
  &:before, :hover {
    background-color: #737373 !important;
  }
}